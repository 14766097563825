import { DateTime } from 'luxon';
import React, { useCallback, useEffect } from 'react';

import { IconFlatCircleInfo } from '../Icon/IconFlatCircleInfo';
import { DateInWeekSelectorControlled } from './DateInWeekSelectorControlled';
import reducer, { SimpleAction } from './reducer';

const luxonSort = (a: DateTime, b: DateTime) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

const isFriday = (date: DateTime): boolean =>
  date?.setLocale('en-US').toLocaleString({ weekday: 'long' }) === 'Friday';

export interface Props {
  referenceDate: DateTime;
  appointmentSlots: DateTime[];
  onChangeSelectedDate: (date: DateTime | null) => void;
  setConfirmEnabled: (enabled: boolean) => void;
}

const DateInWeekSelector: React.FC<Props> = ({
  referenceDate,
  appointmentSlots,
  onChangeSelectedDate,
  setConfirmEnabled,
}) => {
  const sortedSlots = appointmentSlots.sort(luxonSort);
  const firstDate = sortedSlots[0] ?? referenceDate;
  const [{ currentWeek, showTimeSlotsFor, selectedTimeSlot }, dispatch] =
    React.useReducer(reducer, {
      currentWeek: firstDate.startOf('week').minus({ days: 1 }), // Luxon week starts on Monday by default and can't be configured or changed
      showTimeSlotsFor: firstDate,
      selectedTimeSlot: {
        slot: firstDate,
        selectedBy: 'system' as const,
      },
    });
  const fridaySlots = sortedSlots.filter(isFriday);

  // Must ensure our initial, automatic selection is propagated up
  useEffect(() => {
    onChangeSelectedDate(selectedTimeSlot?.slot ?? null);
    setConfirmEnabled(!!selectedTimeSlot?.slot);
  }, [selectedTimeSlot?.slot, onChangeSelectedDate, setConfirmEnabled]);

  const dispatchWithCallback = useCallback(
    (action: SimpleAction) => {
      dispatch({
        ...action,
        callback: () => {},
      });
    },
    [dispatch],
  );

  const fridaySelected = showTimeSlotsFor && isFriday(showTimeSlotsFor);

  return (
    <div className="w-full flex flex-col gap-6">
      <DateInWeekSelectorControlled
        currentWeek={currentWeek}
        selectedDate={showTimeSlotsFor}
        referenceDate={referenceDate}
        appointmentSlots={appointmentSlots}
        warningDates={fridaySlots}
        onPrevWeek={() => {
          setConfirmEnabled(false); // disable Confirm button when switching week
          dispatchWithCallback({
            type: 'PREV_WEEK',
            payload: { available: sortedSlots },
          });
        }}
        onNextWeek={() => {
          setConfirmEnabled(false); // disable Confirm button when switching week
          dispatchWithCallback({
            type: 'NEXT_WEEK',
            payload: { available: sortedSlots },
          });
        }}
        onSelectDate={(newDate: DateTime<boolean>) => {
          dispatchWithCallback({
            type: 'SELECT_DAY',
            payload: { selected: newDate, available: sortedSlots },
          });
        }}
      />
      {fridaySelected ? (
        <div className="flex space-x-3 border px-6 py-4 rounded-lg text-s font-medium border-orange-500 bg-orange-50 text-orange-500">
          <div className="my-1">
            <IconFlatCircleInfo />
          </div>
          <div className="text-left">
            Friday appointments typically begin work on the vehicle the next
            business day.
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DateInWeekSelector;
